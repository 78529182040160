@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(MaterialIcons-Regular.woff); /* For IE6-8 */
    src: local('../public/icon-font/MaterialIcons-Regular.woff'),
    local('MaterialIcons-Regular'),
    url(MaterialIcons-Regular.woff2) format('woff2'),
    url(MaterialIcons-Regular.woff) format('woff'),
    url(MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    margin:0;
    padding:0;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}
.material-icons.md-14 { font-size: 12px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.material-icons.black {color:#3a3a3a;}
.material-icons.white {color:#ffffff;}
.material-icons.lightGrey {color:#f5f5f5;}
.material-icons.semiLightGrey {color:#f5f5f5;}
.material-icons.red {color:#ef5350;}
.material-icons.green {color:#66bb6a;}
.material-icons.lightBlue {color:#1e88e5;}
.material-icons.blue {color:#0091ea;}
.material-icons.darkBlue {color:#1976d2;}
.material-icons.orange {color: #ffc324;}

.material-icons.hidden {visibility: hidden;}

.material-icons.spin{
    animation: icon-spin infinite 10s linear;}


@keyframes icon-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

